import styled, { css } from "styled-components"

export const HighlightSection = styled.section`
  margin-block: var(--space-m);
  background: var(--text-color);
  color: var(--background-color);

  h1 {
    font-size: calc(var(--size-xxl) * 1em);
    line-height: calc(var(--leading) / var(--size-xxl) * 2);
    margin-bottom: calc(var(--leading) / var(--size-xxl) * 2);
    text-transform: uppercase;
  }

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    h1 {
      font-size: clamp(
        calc(var(--size-xxl) * 1em),
        6.5vw,
        calc(var(--size-huge) * 1em)
      );
    }
  }
`

export const HighlightSectionInner = styled.div`
  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    display: flex;

    ${({ isReverse }) =>
      isReverse &&
      css`
        flex-direction: row-reverse;
      `}
  }
`

export const HighlightContent = styled.div`
  flex: 0 1 60%;
  padding-inline: 1.11111rem;
  padding-block: var(--space-m);

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    padding-inline: 2.22222rem;
    padding-block: var(--space-xl);
    display: grid;
    place-content: center;
  }
`

export const HighlightContentInner = styled.div`
  span {
    display: inline-block;
  }

  ${({ isEven }) =>
    (isEven &&
      css`
        h1 > span:nth-of-type(even) {
          display: block;
        }
      `) ||
    (isEven === false &&
      css`
        h1 > span:nth-of-type(odd) {
          display: block;
        }
      `)}

  /* Remove the extra left-margin to specific images
   * based on the title of the Highlight.
   * Use it for art direction tweaks every time
   * a new type of Highlight is added.
  */
  ${({ title }) =>
    (title.includes("Rooms") &&
      css`
        h1 > div:nth-of-type(2) {
          margin-inline-start: 0 !important;
        }
      `) ||
    (title.includes("Location") &&
      css`
        h1 > div:nth-of-type(1) {
          margin-inline-start: 0 !important;
        }
      `)}

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    width: 28rem;
  }
`

export const HighlightImage = styled.div`
  flex: 0 1 40%;

  > div {
    aspect-ratio: 1 / 1.5;
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    .gatsby-image-wrapper {
      aspect-ratio: auto;
    }
  }
`
