import React, { useContext } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import loadable from "@loadable/component"

// Context
import { CursorContext } from "../context/cursorContext"

// Styles
import {
  HighlightContent,
  HighlightContentInner,
  HighlightImage,
  HighlightSection,
  HighlightSectionInner,
} from "../styles/highlightStyles"
import { PageLink } from "../styles/globalStyles"

// Loadable Components
const GoogleMapReact = loadable(() => import("google-map-react"))

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData, description } = node.data.target
      return (
        <GatsbyImage
          image={getImage(gatsbyImageData)}
          alt={description}
          style={{
            display: "inline-block",
            width: "1em",
            verticalAlign: "baseline",
            margin: "0 .2em .01em",
          }}
        />
      )
    },
  },
}

const Highlight = ({
  title,
  caption,
  cta,
  image,
  location,
  link,
  isEven,
  isReverse,
}) => {
  const { handleMouseHover } = useContext(CursorContext)

  const renderMarker = (map, maps) => {
    let marker = new maps.Marker({
      position: {
        lat: location.lat,
        lng: location.lon,
      },
      map,
      title: "The Bed and Breakfast S.Stae",
    })
    return marker
  }

  return (
    <HighlightSection
      onMouseEnter={e => handleMouseHover(e, "inverted")}
      onMouseLeave={e => handleMouseHover(e, "inverted")}
    >
      <HighlightSectionInner isReverse={isReverse}>
        <HighlightContent>
          <HighlightContentInner isEven={isEven} title={title}>
            <h1>{renderRichText(caption, options)}</h1>
            <PageLink
              onMouseEnter={e => handleMouseHover(e, "hovered")}
              onMouseLeave={e => handleMouseHover(e, "hovered")}
              to={link}
            >
              →&emsp;{cta}
            </PageLink>
          </HighlightContentInner>
        </HighlightContent>
        <HighlightImage>
          {image ? (
            <GatsbyImage image={getImage(image)} alt={image.description} />
          ) : (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
              }}
              defaultCenter={{
                lat: location.lat,
                lng: location.lon,
              }}
              defaultZoom={18}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
            />
          )}
        </HighlightImage>
      </HighlightSectionInner>
    </HighlightSection>
  )
}

export default Highlight
